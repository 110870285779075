<template>
    <div id="home" class="flex flex-column lg:flex-row w-full">
        <div class="flex flex-column bg-bluegray-700 lg:w-30rem xl:w-4 px-2 sm:px-4 sm:px-6">
            <h1 class="flex flex-column w-full pt-4">
                <span class="text-center text-xl sm:text-2xl md:text-4xl font-bold text-white nowrap">Services privés
                </span>
                <span class="text-center text-xl sm:text-2xl md:text-4xl font-bold text-white">
                    en
                </span>
                <span class="text-center text-xl sm:text-2xl md:text-4xl font-bold text-white nowrap">
                    éducation spécialisée
                </span>
            </h1>
            <div class="flex w-full pt-3 md:pt-4 lg:pt-6 justify-content-center">
                <span class="font-bold text-white text-sm md:text-base lg:text-lg text-justify md:text-left">Membre de
                    l'<a class="text-white" href="https://www.aeesq.ca/fr/educateur-specialise">AEESQ</a>, j'offre des
                    services
                    accessibles
                    et adaptés
                    aux besoins des enfants et des parents de Drummondville et les environs.
                </span>
            </div>
            <div
                class="flex flex-column md:flex-row flex-wrap lg:flex-column pt-3 md:pt-4 pl-4 justify-content-center gap-2 lg:gap-4">
                <div>
                    <span class="font-bold text-white pr-1">•</span><span
                        class="font-bold text-white text-sm md:text-base lg:text-lg white-space-nowrap">Stimulation
                        individuelle
                    </span>
                </div>
                <div>
                    <span class="font-bold text-white pr-1">•</span><span
                        class="font-bold text-white text-sm md:text-base  lg:text-lg white-space-nowrap">Ateliers
                        individuels </span>
                </div>
                <div>
                    <span class="font-bold text-white pr-1">•</span><span
                        class="font-bold text-white text-sm md:text-base  lg:text-lg white-space-nowrap">Coaching
                        familial </span>
                </div>
            </div>

            <div class="flex justify-content-center py-3 md:py-4 xl:py-6">
                <Button name="contact" v-scroll-to="'#contact'"
                    class="w-6 min-w-min justify-content-center font-bold white-space-nowrap">Contactez
                    moi</Button>
            </div>
        </div>
        <div class="flex overflow-hidden justify-content-center">
            <img class="h-20rem md:h-auto md:w-full lg:w-auto lg:h-full"
                alt="Intervenante encourageant une enfant dans son activité" src=" /images/header_full.webp" />
        </div>
    </div>
</template>

<style scoped>
.nowrap {
    text-wrap: nowrap;
}
</style>
<template>
  <div ref="root">
    <div @click="toggleConfigurator">
      <i class="pi pi-bars text-gray-400"></i>
    </div>

    <div id="layout-config" :class="containerClass">
      <Button class="p-button-danger layout-config-close p-button-rounded p-button-text" icon="pi pi-times"
        @click="hideConfigurator" :style="{ 'z-index': 1 }"></Button>

      <div class="layout-config-content">
        <div class="flex flex-nowrap flex-column">
          <div class="mr-3">
            <a href="#" v-scroll-to="'#home'" @click="hideConfigurator"
              class="text-xl font-bold text-600 no-underline">Accueil</a>
          </div>
          <div class="mr-3">
            <a href="#" v-scroll-to="'#services'" @click="hideConfigurator"
              class="text-xl  font-bold text-600 no-underline">Services</a>
          </div>
          <div class="mr-3">
            <a href="#" v-scroll-to="'#about'" @click="hideConfigurator"
              class="text-xl nowrap font-bold text-600 no-underline">À propos</a>
          </div>
          <div class="mr-3">
            <a href="#" v-scroll-to="'#pricing'" @click="hideConfigurator"
              class="text-xl font-bold text-600 no-underline">Tarification</a>
          </div>
          <div class="mr-3">
            <a href="#" v-scroll-to="'#contact'" @click="hideConfigurator"
              class="text-xl font-bold text-600 no-underline">Contact</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

let active = ref(false)
let outsideClickListener = null
const root = ref(null)
const toggleConfigurator = (event) => {
  active.value = !active.value
  event.preventDefault()

  if (active.value) {
    bindOutsideClickListener()
  } else {
    unbindOutsideClickListener()
  }
}

const hideConfigurator = (event) => {
  active.value = false
  unbindOutsideClickListener()
  event.preventDefault()
}

const bindOutsideClickListener = () => {
  if (!outsideClickListener) {
    outsideClickListener = (event) => {
      if (active.value && isOutsideClicked(event)) {
        active.value = false
      }
    }
    document.addEventListener('click', outsideClickListener)
  }
}

const unbindOutsideClickListener = () => {
  if (outsideClickListener) {
    document.removeEventListener('click', outsideClickListener)
    outsideClickListener = null
  }
}

const isOutsideClicked = (event) => {
  return !(root.value.isSameNode(event.target) || root.value.contains(event.target))
}

const containerClass = computed(() => {
  return ['layout-config', { 'layout-config-active': active.value }]
})
</script>
<style scoped>
.layout-config {
  position: fixed;
  top: 0;
  padding: 0;
  right: 0;
  width: 15rem;
  z-index: 1001;
  height: 27vh;
  transform: translateX(100%);
  transition: transform 0.3s;
  backface-visibility: hidden;
  box-shadow:
    0px 3px 5px rgba(0, 0, 0, 0.02),
    0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  color: var(--text-color);
  background-color: var(--surface-overlay);

  &.layout-config-active {
    transform: translateX(0);
  }

  .layout-config-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 1;
  }

  .layout-config-content {
    position: relative;
    overflow: auto;
    height: 27vh;
    padding: 2rem;
    padding-top: 4rem;
  }
}
</style>

import { createApp } from 'vue'
import PrimeVue from 'primevue/config';
import App from './App.vue'
import '/node_modules/primeflex/primeflex.css'
import 'primevue/resources/themes/lara-light-teal/theme.css'
import 'primeicons/primeicons.css'

import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
import InputText from 'primevue/inputtext'
import Card from 'primevue/card'
import MultiSelect from 'primevue/multiselect';

var VueScrollTo = require('vue-scrollto');

createApp(App)
    .use(PrimeVue)
    .use(VueScrollTo, {
        container: "body",
        duration: 500,
        easing: "ease",
        offset: -100,
        force: true,
        cancelable: true,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true
    })
    .component('Button', Button)
    .component('Textarea', Textarea)
    .component('InputText', InputText)
    .component('Card', Card)
    .component('MultiSelect', MultiSelect)
    .mount('#app')

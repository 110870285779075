<template>
    <div id="about" class="w-full flex flex-column p-4 md:px-6 lg:px-8 surface-ground overflow-hidden">
        <div class="flex flex-column xl:flex-row justify-content-center gap-4 border-round-3xl">
            <div class="flex flex-column text-justify xl:max-w-30rem">
                <h2>Qui je suis</h2>
                <p class="px-4">Je suis une éducatrice spécialisée et membre de l'<a class="text-color"
                        href="https://www.aeesq.ca/fr/educateur-specialise">AEESQ</a> qui compte 10 ans d’expérience
                    dans
                    les milieux
                    scolaires.
                    Durant mon parcours, j'ai agi auprès d’une clientèle avec des besoins variés comme le TDA/H, les
                    troubles du comportement, le TSA, la dyspraxie (ou TDC) ainsi que les troubles d’apprentissages.</p>

                <p class="px-4">Maman
                    de deux enfants ayant eu recours à des services en physiothérapie et en ergothérapie, j'étais à
                    même
                    de constater comment mon expérience d’éducatrice spécialisée était profitable à la stimulation et à
                    l’application des moyens à domicile.</p>

                <p class="px-4">Convaincue que mon expertise pouvait être profitable à plusieurs
                    familles et déterminée à soutenir les parents et leurs enfants vivant avec des besoins particuliers,
                    «
                    Karine Fleury, services en éducation spécialisée» a vu le jour.</p>
                <h2>Mission</h2>

                <p class="px-4">"Karine Fleury, services en éducation spécialisée" a pour mission de rendre accessible
                    des
                    services adaptés aux besoins des enfants et des parents. C’est en offrant des services de coaching
                    familial, de stimulation et d’ateliers individuels que Karine Fleury s’implique dans les familles de
                    Drummondville et ses environs.</p>

                <p class="px-4">Contactez-moi dès aujourd'hui pour discuter des moyens par lesquels je peux contribuer
                    au
                    bien-être et au
                    succès de votre enfant. </p>
            </div>
            <div class="flex justify-content-center align-items-start p-4">
                <img class="w-25rem sm:w-auto" alt="Portrait de l'intervenante Karine Fleury"
                    src="/images/KarineFleury.webp" />
            </div>
        </div>
    </div>
</template>

<style scoped>
.center {
    margin-left: auto;
    margin-right: auto;
}
</style>
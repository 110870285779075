<template>
    <div id="contact" class="surface-ground p-4 md:px-6 lg:px-8 overflow-hidden">
        <div class="text-center">
            <div class="font-bold text-xl md:text-2xl lg:text-4xl mb-3">Permettez-moi d'accompagner
                votre famille pour offrir un
                soutien
                adapté aux
                particularités de votre enfant.</div>
        </div>
        <Form @submit="onSubmit" :validation-schema="validationSchema">
            <div class="flex w-full flex-column pt-2">
                <div class="flex w-full flex-wrap">
                    <div class="field w-full md:w-6">
                        <div class="m-2">
                            <span class="p-float-label">
                                <InputText class="w-full" id="name" v-bind="name"
                                    :class="{ 'p-invalid': !!errors.name, 'p-filled': name.value }"
                                    aria-describedby="text-error" />
                                <label for="name">Nom complet*</label>
                            </span>
                            <small id="text-error" class="p-error">{{ errors.name }}</small>
                        </div>
                    </div>
                    <div class="field w-full md:w-6">
                        <div class="m-2">
                            <span class="p-float-label">
                                <InputText class="w-full" id="phone" v-bind="phone"
                                    :class="{ 'p-invalid': !!errors.phone, 'p-filled': phone.value }"
                                    aria-describedby="text-error" />
                                <label for="phone">Téléphone*</label>
                            </span>
                            <small id="text-error" class="p-error">{{ errors.phone }}</small>
                        </div>
                    </div>
                    <div class="field w-full md:w-6">
                        <div class="m-2">
                            <span class="p-float-label">
                                <InputText class="w-full " id="email" v-bind="email"
                                    :class="{ 'p-invalid': !!errors.email, 'p-filled': email.value }"
                                    aria-describedby="text-error" />
                                <label for="email">Courriel*</label>
                            </span>
                            <small id="text-error" class="p-error">{{ errors.email }}</small>
                        </div>
                    </div>
                    <div class="field w-full md:w-6">
                        <div class="m-2">
                            <span class="p-float-label white-space-nowrap overflow-hidden">
                                <MultiSelect id="msservices" v-model="multiselectValue" :options="multiselectValues"
                                    optionLabel="name" class="w-full " />
                                <label for="msservices">Service(s) recherché(s)</label>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="field m-2">
                    <span class="p-float-label">
                        <Textarea name="message" class="w-full h-15rem" v-bind="message"
                            :class="{ 'p-invalid': !!errors.message, 'p-filled': message.value }"
                            aria-describedby="text-error"></Textarea>
                        <label for="email">Message*</label>
                    </span>
                    <small id="text-error" class="p-error">{{ errors.message }}</small>
                </div>
            </div>
            <div class="flex flex-column md:flex-row gep-3" v-if="!sent">
                <div>
                    <div class="g-recaptcha" data-sitekey="6LfjSlcpAAAAABrAAmxt--aJB2msFm_Ih4BOIXAU"></div>
                    <div v-if="recaptchaError" style="color: red;">Veuillez compléter le reCAPTCHA.</div>
                </div>
                <Button name="bsend" class="ml-2" label="Envoie" type="Submit"></Button>
            </div>
            <div class="flex flex-column md:flex-row gep-3" v-if="sent">
                <div>
                    <span class="text-green-400 text-lg font-bold"> Message Envoyé. Merci!</span>
                </div>
            </div>
        </Form>
    </div>
</template>


<script setup>
import { useForm, Form } from 'vee-validate'
import * as yup from 'yup'
import { validateRecaptcha } from '@/recaptcha.js';
import { ref } from 'vue';
import emailjs from '@emailjs/browser';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const { errors, defineInputBinds, validationSchema, resetForm, validate } = useForm({
    validationSchema: yup.object({
        name: yup.string().required('Le nom complet est requis'),
        email: yup.string().email('Un courriel valid est requis').required('Un courriel valid est requis'),
        message: yup.string().required('Le numéro de téléphone est requis'),
        phone: yup.string().matches(phoneRegExp, 'Le numéro n\'est pas valide').required('Le numéro de téléphone est requis'),
    })
})

const name = defineInputBinds('name')
const message = defineInputBinds('message')
const email = defineInputBinds('email')
const phone = defineInputBinds('phone')
let recaptchaError = ref(false);
let sent = ref(false);

const multiselectValues = ref([
    { name: 'Stimulation individuelle', code: 'si' },
    { name: 'Ateliers individuels', code: 'ai' },
    { name: 'Coaching familial', code: 'cf' }
]);

const multiselectValue = ref(null);

const onSubmit = async () => {

    try {
        recaptchaError.value = false; // Reset the error on each submit attempt
        if ((await validate()).valid) {
            await validateRecaptcha().then(() => {
                emailjs
                    .send('service_qvfe8yq', 'template_bnf1ejs', {
                        name: name.value.value,
                        message: message.value.value,
                        email: email.value.value,
                        phone: phone.value.value,
                        services: multiselectValue.value.map(obj => obj.name).join(', ')
                    }, {
                        publicKey: 'rLwI6QFYk8adfxa_6',
                    })
                    .then(() => {
                        sent.value = true;
                        resetForm();
                        multiselectValue.value = null;
                        setTimeout(() => { sent.value = false }, 5000)
                    });
            }, () => { recaptchaError.value = true; });
        }

    } catch (error) {
        recaptchaError.value = true;
    }
};
</script>

<template>
    <div id="services" class="flex flex-column w-full surface-100 gap-6 bg-white p-4 md:px-6 lg:px-8 overflow-hidden">
        <div class="flex px-2 justify-content-center w-full">
            <span class="font-bold text-4xl text-center">Services
                à domicile
            </span>
        </div>

        <div class="flex flex-column-reverse justify-content-center w-full lg:flex-row xl:px-4 gap-3 lg:gap-6 xl:gap-8">
            <div class="flex flex-column lg:w-5">
                <div class="text-900 text-xl mb-3 font-bold">Stimulation individuelle</div>
                <span class="text-700 line-height-3">
                    <p>La stimulation individuelle vise à accompagner l’enfant, qui a déjà un plan
                        d’intervention d’un
                        professionnel ou non, face aux difficultés ou retards observés tels que :
                    </p>
                    <div class="flex flex-column mx-2 mt-4">
                        <div><span class="font-bold pr-1">•</span><span class="">Motricité
                                fine
                            </span></div>
                        <div><span class="font-bold pr-1">•</span><span class="">Motricité
                                globale </span>
                        </div>
                        <div><span class="font-bold pr-1">•</span><span class="">Autonomie
                            </span></div>
                        <div><span class="font-bold pr-1">•</span><span class="">Sensorielle
                            </span>
                        </div>
                    </div>
                </span>
            </div>
            <div class="flex justify-content-center align-content-center">
                <div class="flex h-min justify-content-center md:p-3 md:shadow-2 md:surface-card md:border-round-lg">
                    <img class="w-20rem sm:w-auto" alt="Enfant faisant un exercice stimulant la motricité fine"
                        src="/images/Stimulation.webp" />
                </div>
            </div>
        </div>

        <div class="flex flex-column lg:flex-row  gap-3 lg:gap-6 xl:gap-8 justify-content-center">
            <div class="flex justify-content-center">
                <div class="flex h-min justify-content-center md:p-3 md:shadow-2 md:surface-card md:border-round-lg">
                    <img class="w-20rem sm:w-auto" alt="Enfants dévelopant leur activité social avec un jeu"
                        src="/images/Ateliers.webp" />
                </div>
            </div>
            <div class="flex flex-column lg:w-5 ">
                <div class="text-900 text-xl mb-3 font-bold">Ateliers individuels </div>
                <span class="text-700 line-height-3">
                    <p>Les ateliers individuels sont des rencontres avec votre enfant dans lesquelles des petits
                        ateliers ou des jeux lui seront proposés selon ses besoins :</p>
                    <div class="flex flex-column mx-2 mt-4">
                        <div><span class="font-bold pr-1">•</span><span class="">Gestion
                                des
                                émotions </span>
                        </div>
                        <div><span class="font-bold pr-1">•</span><span class="">Gestion
                                de
                                la colère </span></div>
                        <div><span class="font-bold pr-1">•</span><span class="">Anxiété
                            </span></div>
                        <div><span class="font-bold pr-1">•</span><span class="">Estime
                                de
                                soi </span>
                        </div>
                        <div><span class="font-bold pr-1">•</span><span class="">Habiletés
                                sociales </span></div>
                    </div>
                </span>
            </div>
        </div>

        <div class="flex flex-column-reverse lg:flex-row  gap-3 lg:gap-6 xl:gap-8 justify-content-center">
            <div class="flex flex-column lg:w-5">
                <div class="text-900 text-xl mb-3 font-bold">Coaching familial</div>
                <span class="text-700 line-height-3">
                    <p>Le Coaching familial consiste à accompagner et outiller les parents afin de les aider à
                        intervenir efficacement auprès de leurs enfants. Les difficultés rencontrés sont, par
                        exemple :
                    </p>
                    <div class="flex flex-column mx-1 mt-4">
                        <div><span class="font-bold pr-1">•</span><span class="">Comportementales </span>
                        </div>
                        <div><span class="font-bold pr-1">•</span><span class="">Routines
                            </span></div>
                        <div><span class="font-bold pr-1">•</span><span class="">Relations
                                familiales / Communication </span>
                        </div>
                        <div><span class="font-bold pr-1">•</span><span class="">Etc...
                            </span></div>
                    </div>
                </span>
            </div>
            <div class="flex justify-content-center">
                <div class="flex h-min justify-content-center md:p-3 md:shadow-2 md:surface-card md:border-round-lg">
                    <img class="w-20rem sm:w-auto" alt="Intervenante prenant des notes" src="/images/Coaching.webp" />
                </div>
            </div>
        </div>

        <div class="flex px-2 justify-content-center w-full">
            <span class="font-semibold text-l pb-4">Tous les services sont offerts à domicile, dans l’environnement de
                l’enfant, auprès
                d’une
                clientèle âgée entre
                0 et 12 ans.</span>
        </div>
    </div>
</template>

<style scoped>
.service_img {
    height: 320 !important;
}

.coaching_img {
    height: 560 !important;
}

.atelier_img {
    height: 390 !important;
}
</style>
/* global grecaptcha */
export const validateRecaptcha = () => {
    return new Promise((resolve, reject) => {
        // eslint-disable-next-line
        const response = grecaptcha.getResponse();

        if (response.length == 0)
            reject(new Error('reCAPTCHA validation failed: Token is empty.'));
        resolve()
    })
};